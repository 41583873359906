import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import CTA from '../components/CTA'
import Header from '../components/Header'
import { FAQContainer, FAQs } from '../components/FAQs'
import { AboutContact } from '.'

export default () => (
  <Layout>
    <Helmet>
      <title>Privacy Policy - Queensland Sports Aviators</title>
      <meta
        name="description"
        content="Here at Queensland Sports Aviators, your privacy and online security is important to us. For your full knowledge, please read our privacy policy to determine how we gather and use information."
      />
    </Helmet>
    <Header>
      <h1>Privacy Policy</h1>
    </Header>
    <FAQContainer>
      <FAQs>
        <h4>Your Privacy</h4>
        <div>
          <p>
            Your privacy is important to us. To better protect your privacy we
            provide this notice explaining our online information practices and
            the choices you can make about the way your information is collected
            and used. To make this notice easy to find, we make it available on
            our homepage and at every point where personally identifiable
            information may be requested.
          </p>

          <h4>Our Commitment To Childrens Privacy</h4>

          <p>
            Protecting the privacy of the very young is especially important.
            For that reason, Queensland Sports Aviators. will never collect or
            maintain information at our website from those we actually know are
            under 18, and no part of our website is structured to attract anyone
            under 18.
          </p>

          <h4>Collection of Personal Information</h4>

          <p>
            When visiting Queensland Sports Aviators, the IP address used to
            access the site will be logged along with the dates and times of
            access. This information is purely used to analyse trends,
            administer the site, track users movement and gather broad
            demographic information for internal use. Most importantly, any
            recorded IP addresses are not linked to personally identifiable
            information.
          </p>

          <p>
            We may use technology to track the patterns of behaviour of visitors
            to our site. This can include using a “cookie” which would be stored
            on your browser. You can usually modify your browser to prevent this
            happening. The information collected in this way can be used to
            identify you unless you modify your browser settings.
          </p>

          <p>
            To enable us to process your membership we will need to collect
            personally identifiable information about you such as name, billing
            and delivery address, email address, phone numbers. Payments are
            processed through Paypal. Credit card details remain with the third
            party payment service providers and are not held by us.
          </p>

          <p>
            From time to time we may use your name and email address to provide
            you with relevant information in relation to your membership. You
            will have the ability to unsubscribe from any or all communications
            that we send to you.
          </p>

          <p>
            We will never sell, rent or provide your personally identifiable
            information to third parties, unless required to do so by law.
          </p>

          <h4>Links to third party Websites</h4>

          <p>
            We have included links on this site for your use and reference. We
            are not responsible for the privacy policies on these websites. You
            should be aware that the privacy policies of these sites may differ
            from our own.
          </p>

          <h4>Changes to this Privacy Statement</h4>

          <p>
            The contents of this statement may be altered at any time, at our
            discretion.
          </p>

          <p>
            If you have any questions regarding the Privacy Policy of Just
            Patios.then you may contact us at through the contact page
          </p>
        </div>
      </FAQs>
    </FAQContainer>
  </Layout>
)
